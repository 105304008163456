<template>
  <div class="service">
    <el-form :inline="true" :model="form" class="demo-form-inline">
      <el-form-item label="用户Uid">
        <el-input v-model="form.userId" placeholder="请输入用户Uid"></el-input>
      </el-form-item>
      <el-form-item label="时间">
        <el-date-picker
          is-range
          format="yyyy-MM-dd"
          clearable
          v-model="time"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="pickerChange"
          style="width: 280px"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="doFilter">查询</el-button>
        <el-button type="primary" @click="noserch">重置</el-button>
        <el-button type="success" @click="addWater()">新增</el-button>
        <el-button type="success" @click="centerDialogtwo = true">批量修改</el-button>
        <el-button type="success" style="margin: 0 15px" @click="exportData()">生成表格</el-button>
        <download-excel v-if="derivedForm" class="export" :data="derivedForm" :fields="jsonFields" type="xls" name="流水管理表.xls">
          下载表格
        </download-excel>
      </el-form-item>
    </el-form>
    <div class="table">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="userId" label="用户Uid"> </el-table-column>
        <el-table-column label="交易类型" width="180">
          <template slot-scope="scope">
            <span v-if="scope.row.dealType == 1">收入</span>
            <span v-else>卖出</span>
          </template>
        </el-table-column>
        <el-table-column prop="typeDesc" label="类型描述"> </el-table-column>
        <el-table-column prop="amount" label="交易金额"> </el-table-column>
        <el-table-column prop="tokenNum" label="通证名称"> </el-table-column>

        <el-table-column prop="beforeAmount" label="出账前资金"> </el-table-column>
        <el-table-column prop="afterAmount" label="出账后资金"> </el-table-column>
        <el-table-column prop="createTime" label="创建时间"> </el-table-column>
      </el-table>
    </div>
    <!-- 底部分页 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.currentPage"
        :page-sizes="[10, 15, 20, 30]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 新增 -->
    <el-dialog title="新增" :visible.sync="centerDialogVisible" width="30%" center :before-close="closeWater">
      <span>
        <el-form label-position="left" label-width="80px" :model="formLabelAlign">
          <el-form-item label="用户Uid">
            <el-input maxlength="6" type="number" v-model="formLabelAlign.userId"></el-input>
          </el-form-item>
          <el-form-item label="金额">
            <el-input type="number" v-model="formLabelAlign.amount"></el-input>
          </el-form-item>
          <el-form-item label="通证id">
            <el-select v-model="formLabelAlign.certificateId" placeholder="请选择通证">
              <el-option v-for="item in options" :key="item.certificateId" :label="item.tokenName" :value="item.certificateId"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="类型">
            <el-select v-model="formLabelAlign.type" placeholder="请选择">
              <el-option v-for="item in optionsTwo" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="二级密码">
            <el-input type="text" v-model="formLabelAlign.pwd"></el-input>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeWater()">取 消</el-button>
        <el-button type="primary" @click="addNewWater()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="批量新增" :visible.sync="centerDialogtwo" width="50%" center :before-close="closeConfirm">
      <span>
        <el-form label-position="left" label-width="auto"  :model="batchForm">
            <el-form-item label="批量导入">
              <el-upload
                action="#"
                :auto-upload="false"
                :on-change="onChange"
                :on-exceed="tips"
                :limit="1"
                ref="upload"
                :show-file-list="false"
                accept=".xls,.xlsx"
                style="margin-bottom: 10px;"
              >
                <el-button type="primary">上传excel</el-button>
            </el-upload>
            </el-form-item>
            <el-form-item label="操作类型">
              <el-select v-model="batchForm.type" placeholder="请选择">
                <el-option v-for="item in optionsTwo" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="通证">
              <el-select v-model="batchForm.certificateId" placeholder="请选择通证">
                <el-option v-for="item in options" :key="item.certificateId" :label="item.tokenName" :value="item.certificateId"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="二级密码">
              <el-input style="width:300px;" placeholder="请输入二级密码" type="text" v-model="batchForm.pwd"></el-input>
            </el-form-item>
          </el-form>
        <el-table
          :data="batchForm.list"
          border
          style="width: 100%">
          <el-table-column
            prop="uid"
            label="UID"
            >
          </el-table-column>
          <el-table-column
            prop="amount"
            label="数量">
          </el-table-column>
        </el-table>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeConfirm()">取 消</el-button>
        <el-button type="primary" @click="confirmadd()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
const xlsx = require("xlsx");
import { xmlUrl } from '../../utils/xmlurl'
export default {
  data() {
    return {
      form: {
        userId: '',
        startTime: null,
        endTime: null,
        currentPage: 1,
        pageSize: 20
      },
      derivedForm: '',
      formLabelAlign: {
        amount: '',
        certificateId: 0,
        type: '',
        pwd: '',
        userId: ''
      },
      batchForm:{
        certificateId:0,
        type:'',
        list:[],
        pwd:''
      },
      time: [],
      options: [{ certificateId: 0, tokenName: 'CNY', tokenNum: '111111' }],
      optionsTwo: [
        {
          value: '1',
          label: '增加'
        },
        {
          value: '2',
          label: '减少'
        }
      ],
      jsonFields: {
        用户UID: 'userId',
        交易类型: 'dealType',
        类型描述: 'typeDesc',
        交易金额: 'amount',
        通证名称: 'tokenNum',
        出账前资金: 'beforeAmount',
        出账后资金: 'afterAmount',
        创建时间: 'createTime'
      },
      centerDialogVisible: false,
      centerDialogtwo:false,
      tableData: [],
      exeData:[],
      total: 0
    }
  },
  mounted() {
    this.getData()
    this.getSeletor()
  },
  methods: {
    // 获取通证下拉框
    async onChange(file) {
      /**
       * 1. 使用原生api去读取好的文件
       * */
      // console.log("原始上传的文件", file);
      // 读取文件不是立马能够读取到的，所以是异步的，使用Promise
      let dataBinary = await new Promise((resolve) => {
        // Web API构造函数FileReader，可实例化对象，去调用其身上方法，去读取解析文件信息
        let reader = new FileReader(); // https://developer.mozilla.org/zh-CN/docs/Web/API/FileReader
        // console.log("实例化对象有各种方法", reader);
        reader.readAsBinaryString(file.raw); // 读取raw的File文件
        reader.onload = (ev) => {
          // console.log("文件解析流程进度事件", ev);
          resolve(ev.target.result); // 将解析好的结果扔出去，以供使用
        };
      });
      // console.log("读取出的流文件", dataBinary);

      /**
       * 2. 使用xlsx插件去解析已经读取好的二进制excel流文件
       * */
      let workBook = xlsx.read(dataBinary, { type: "binary", cellDates: true });
      // excel中有很多的sheet，这里取了第一个sheet：workBook.SheetNames[0]
      let firstWorkSheet = workBook.Sheets[workBook.SheetNames[0]];
      // 分为第一行的数据，和第一行下方的数据
      const header = this.getHeaderRow(firstWorkSheet);
      // console.log("读取的excel表头数据（第一行）", header);
      const data = xlsx.utils.sheet_to_json(firstWorkSheet);
      // console.log("读取所有excel数据", data);
      for(let i = 0; i < data.length; i++) {
        this.exeData.push({amount:data[i].数量,uid:data[i].UID})
      }
      this.batchForm.list = this.exeData
    },
    getHeaderRow(sheet) {
      console.log(sheet)
      const headers = []; // 定义数组，用于存放解析好的数据
      const range = xlsx.utils.decode_range(sheet["!ref"]); // 读取sheet的单元格数据
      let C;
      const R = range.s.r;
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) {
        /* walk every column in the range */
        const cell = sheet[xlsx.utils.encode_cell({ c: C, r: R })];
        /* find the cell in the first row */
        let hdr = "UNKNOWN " + C; // <-- replace with your desired default
        if (cell && cell.t) hdr = xlsx.utils.format_cell(cell);
        headers.push(hdr);
      }
      return headers; // 经过上方一波操作遍历，得到最终的第一行头数据
    },
    getData() {
      console.log(this.form)
      this.axios.get('/admin/userWater/showWater', this.form).then(res => {
        console.log(res, '111111111111')
        this.tableData = res.data.data.records
        this.total = res.data.data.total
      })
    },
    addWater() {
      this.centerDialogVisible = true
    },
    // 获取数字通证下拉框
    getSeletor() {
      this.axios.get('/admin/certificate/getDropDownBox', {}).then(res => {
        console.log(res, '222222222222')
        this.options.push(...res.data.data)
        // this.options.push({ certificateId: 0, tokenName: 'CNY', tokenNum: '111111' })
      })
    },
    tips() {
      this.$message.error('最多上传一个文件')
    },
    // 取消批量添加
    closeConfirm() {
      this.exeData = []
      this.$refs['upload'].clearFiles()
      this.batchForm = this.$options.data().batchForm
      this.batchForm.certificateId = 0
      this.centerDialogtwo = false
    },
    // 批量添加
    confirmadd() {
      const that = this
      console.log(this.batchForm)
      if(this.batchForm.list.length == 0) {
        this.$message.error('请上传Excel表格')
      }else if(this.batchForm.type == '') {
        this.$message.error('请选择操作类型')
      }else if(this.batchForm.pwd == '') {
        this.$message.error('请输入二级密码')
      }else {
        console.log(this.batchForm)
        that.axios.post('/admin/userWater/addListAmount',this.batchForm).then( res => {
          if(res.data.code == 200) {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.exeData = []
            this.$refs['upload'].clearFiles()
            this.batchForm = this.$options.data().batchForm
            this.batchForm.certificateId = 0
            this.centerDialogtwo = false
            that.getData()
          }else {
            this.$message({
              message: res.data.data,
              type: 'error'
            })
          }
        })
      }
      // 
    },
    // 时间
    pickerChange(val) {
      this.form.startTime = val[0]
      this.form.endTime = val[1]
    },
    // 添加流水
    addNewWater() {
      console.log(this.formLabelAlign)
      const that = this
      if (this.formLabelAlign.userId == '') {
        this.$message.error('请输入用户Uid')
      } else if (this.formLabelAlign.amount == '') {
        this.$message.error('请输入金额')
      } else if (this.formLabelAlign.amount <= 0) {
        this.$message.error('请输入大于零且不等于0的数字')
      } else if (this.formLabelAlign.type == '') {
        this.$message.error('请选择类型')
      } else if (this.formLabelAlign.pwd == '') {
        this.$message.error('请输入二级密码')
      } else {
        this.$confirm('确定添加', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            that.axios.post('/admin/userWater/updateBalance', that.formLabelAlign).then(res => {
              console.log(res)
              this.$message({
                message: '添加成功',
                type: 'success'
              })
              that.centerDialogVisible = false
              that.formLabelAlign.userId = ''
              that.formLabelAlign.amount = ''
              that.formLabelAlign.certificateId = 0
              that.formLabelAlign.type = ''
              that.getData()
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            })
          })
      }
    },
    // 获取所有需要导出的数据
    exportData() {
      this.$message.success('生成中，请稍候');
      this.axios.get('/admin/userWater/getAll', this.form).then(res => {
        console.log(res)
        setTimeout(function(){
          window.open(xmlUrl + res.data.data)
        }, 5000);
        // window.open(xmlUrl + res.data.data)
      })

      // this.axios.get('/admin/userWater/getTime', {}).then(res => {
      //   if (res.data.code == -1) {
      //     this.axios.get('/admin/userWater/getAll', this.form).then(res => {
      //       console.log(res)
      //       if (res.data.code == 200) {
      //         window.open(`http://47.109.88.30:9071/${res.data.data}`)
      //       } else {
      //         console('2222')
      //       }
      //     })
      //     // 注释这里的原因是后端说这个接口太耗资源，该用列表接口
      //     // this.axios.get('/admin/userWithdraw/withdrawExcel', {}).then(res => {
      //     // this.form.pageSize = 200
      //     // this.axios.get('/admin/userWater/showWater', this.form).then(res => {
      //     //   console.log(res)
      //     //   // this.derivedForm = res.data.data
      //     //   this.form.pageSize = 10
      //     //   this.derivedForm = res.data.data.records
      //     //   this.derivedForm.forEach(item => {
      //     //     item.dealType = item.dealType == 1 ? '收入' : '卖出'
      //     //   })
      //     // })
      //   } else {
      //     this.$message.error('本时段无法生成表格')
      //   }
      // })
    },
    // 取消新增
    closeWater() {
      this.formLabelAlign.userId = ''
      this.formLabelAlign.amount = ''
      this.formLabelAlign.certificateId = 0
      this.formLabelAlign.type = ''
      this.centerDialogVisible = false
    },
    doFilter() {
      this.getData()
    },
    noserch() {
      this.form.userId = ''
      this.form.time = ''
      this.form.endTime = ''
      this.form.startTime = ''
      this.form.currentPage = 1
      this.form.pageSize = 20
      this.time = []
      this.getData()
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.form.pageSize = val
      this.getData()
    },
    handleCurrentChange(val) {
      this.form.currentPage = val
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
.theQuery {
  height: 70px;
  display: flex;
  margin-left: 30px;
  .theQuery-Tow {
    width: 200px;
    height: 70px;
    border: 1px solid red;
    margin-right: 45px;
  }
}
.pagination {
  padding: 60px 0 0 110px;
}
// 导出样式
.export {
  color: #fff;
  background-color: #409eff;
  width: 90px;
  min-width: 90px;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  line-height: 40px;
  font-size: 12px;
  display: inline-block;
}
.export:hover {
  cursor: pointer;
}
.operation {
  display: flex;
  div {
    margin-left: 12px;
    font-size: 12px;
    color: #409eff;
  }
  div:hover {
    cursor: pointer;
  }
}
</style>